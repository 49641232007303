import { render, staticRenderFns } from "./current-loans.vue?vue&type=template&id=3d182982&scoped=true"
import script from "./current-loans.vue?vue&type=script&lang=js"
export * from "./current-loans.vue?vue&type=script&lang=js"
import style0 from "./current-loans.vue?vue&type=style&index=0&id=3d182982&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d182982",
  null
  
)

export default component.exports