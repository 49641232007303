import { render, staticRenderFns } from "./employment-details.vue?vue&type=template&id=6778726e&scoped=true"
var script = {}
import style0 from "./employment-details.vue?vue&type=style&index=0&id=6778726e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6778726e",
  null
  
)

export default component.exports